import React from "react";
import { MyAdminPanelWrapper } from "features/AdminPanel/modules/MyAdminPanelWrapper/MyAdminPanelWrapper";
import {
  MANAGE_OFFERINGS_FEATURE_KEY,
  MANAGE_OFFERINGS_HEADER_TITLE,
} from "features/ManageOfferings/constants/ManageOfferings.constants";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import CourseAnalytics from "ui/pages/CourseAnalytics/CourseAnalytics";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { COURSE_ANALYTICS_LEARN_MORE_HREFS } from "ui/pages/CourseAnalytics/data";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { useParams } from "react-router-dom";
import { getManageOfferingRedirectUrl } from "features/ManageOfferings/utils/ManageOffering.utils";

export const CourseAnalyticsWrapper = withComponentLibraryTheme((props) => {
  const isDesktop = useDesktopMediaQuery();
  const { listing_uuid } = useParams();
  const backUrl = getManageOfferingRedirectUrl({ listingId: listing_uuid });

  return (
    <MyAdminPanelWrapper
      featureKey={`${MANAGE_OFFERINGS_FEATURE_KEY}/${listing_uuid}`}
      classes={{ childrenComponentStyle: "bg-transparent" }}
      hideHeader={false}
      headerTitle={
        !isDesktop ? "Course Analytics" : MANAGE_OFFERINGS_HEADER_TITLE
      }
      {...(!isDesktop
        ? {
            headerRight: (
              <LearnMoreCta
                href={COURSE_ANALYTICS_LEARN_MORE_HREFS.COURSE_ANALYTICS}
              />
            ),
            backUrl,
          }
        : {})}
    >
      <CourseAnalytics {...props} />
    </MyAdminPanelWrapper>
  );
});
