import React from "react";
import { MyAdminPanelWrapper } from "features/AdminPanel/modules/MyAdminPanelWrapper/MyAdminPanelWrapper";
import { EXLY_FEEDBACKS_MORE_HREFS } from "ui/pages/ExlyFeedbacks/data/module_constants";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import PublishedFeedbacks from "ui/pages/ExlyFeedbacks/pages/PublishedFeedbacks";
import { useParams } from "react-router-dom";
import { getManageOfferingRedirectUrl } from "features/ManageOfferings/utils/ManageOffering.utils";
import {
  MANAGE_OFFERINGS_FEATURE_KEY,
  MANAGE_OFFERINGS_HEADER_TITLE,
} from "features/ManageOfferings/constants/ManageOfferings.constants";
import { getParamValueFromRouteProps } from "utils/Utils";
import { section_key } from "features/AdminPanel/constants/AdminPanel.constants";
import { SectionHeading } from "features/AdminPanel/modules/SectionHeading/SectionHeading";
import { OfferingFeedbackSettingsBtn } from "../OfferingFeedbackSettingsBtn/OfferingFeedbackSettingsBtn";
import { FEEDBACK_BACK_NAVIGATE_PARAM } from "../../constants/OfferingFeedback.constants";
import { SectionConfigWrapper } from "features/AdminPanel/modules/SectionConfigWrapper/SectionConfigWrapper";

export const OfferingPublishedFeedbacks = (props) => {
  const isDesktop = useDesktopMediaQuery();
  const { listing_uuid } = useParams();
  const backUrl = getManageOfferingRedirectUrl({ listingId: listing_uuid });
  const key = getParamValueFromRouteProps(props, section_key);
  const featureKey = `${MANAGE_OFFERINGS_FEATURE_KEY}/${listing_uuid}`;
  const navigateBack = getParamValueFromRouteProps(
    props,
    FEEDBACK_BACK_NAVIGATE_PARAM
  );
  return (
    <MyAdminPanelWrapper
      featureKey={featureKey}
      classes={{ childrenComponentStyle: "bg-transparent" }}
      hideHeader={false}
      headerTitle={!isDesktop ? "Feedback" : MANAGE_OFFERINGS_HEADER_TITLE}
      {...(!isDesktop
        ? {
            headerRight: (
              <LearnMoreCta href={EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK} />
            ),
            ...(!navigateBack && { backUrl }),
          }
        : {})}
    >
      <SectionConfigWrapper featureKey={featureKey} sectionKey={key}>
        {isDesktop && (
          <SectionHeading
            heading={"Feedback"}
            showBackIcon={navigateBack}
            headerRight={
              <div className="tw-flex">
                <LearnMoreCta href={EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK} />
                <OfferingFeedbackSettingsBtn />
              </div>
            }
            classes={{
              heading: isDesktop ? "!tw-border-b-0 !tw-pb-1" : "",
            }}
          />
        )}
        <PublishedFeedbacks
          {...props}
          layoutDesktopMargin="0px"
          titleClassname="!tw-text-[24px]"
          hideFilters
          showSettings
          isManageOfferings
        />
      </SectionConfigWrapper>
    </MyAdminPanelWrapper>
  );
};
