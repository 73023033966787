import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import { MANAGE_OFFERING_ASSETS_RELATIVE_PATH } from "../constants/ManageOfferings.constants";
import { is_empty } from "features/Common/utils/common.utils";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import {
  LISTINGS_ROUTE_IDS,
  LISTINGS_ROUTE_KEYS,
} from "features/Listings/constants/Listings.urlPaths";
import { FEEDBACK_SECTION_KEY } from "../modules/OfferingFeedback/constants/OfferingFeedback.constants";
import { navigateTo } from "utils/urlUtils";

export const getManageOfferingAsset = (key) => {
  if (is_empty(key)) return;
  return getS3AssetCDNUrl(`${MANAGE_OFFERING_ASSETS_RELATIVE_PATH}/${key}`);
};

export const getManageOfferingRedirectUrl = ({ listingId }) => {
  return listingId
    ? `/${app_route_ids[app_route_keys.manage_offering]}/${listingId}`
    : LISTINGS_ROUTE_IDS[LISTINGS_ROUTE_KEYS.LISTINGS_LIST];
};

export const feedbackEmptyStateOnClickFromManageOffering = ({
  listing_uuid,
}) => {
  navigateTo(app_route_keys.OFFERING_FEEDBACK_FORM, {
    args: {
      listing_uuid,
      section_key: FEEDBACK_SECTION_KEY,
    },
  });
};
